<template>
    <div v-html="pageContent" class="mt-5"></div>
    <div class="row participants">
        <div class="col-lg-6 col-12" v-for="participant in participants" :key="participant.key">
        <div class="team">
            <div class="left">{{participant.place}}</div>
            <div class="right">
            <div class="team-name">{{participant.teamName}}</div>
            <div class="university">{{participant.university[language]}}</div>
            </div>
            </div>
        </div>
    </div>
</template>

<script>

export default{
  name:'Participants',
  props:['globalData', 'pageContent'],
  data(){
    return{

    }
  },  
  computed:{
    language(){
        return this.globalData.init.language
    },
    participants(){
        let participants=[]
        if(this.$parent.$options.name=='MainPage'){
           participants = this.globalData.init.participants.slice(0,6);
           participants.map( (participant, index) => {
                const newPropsObj = {
                    'place':this.globalData.init.participants.length - index
                };
                return Object.assign(participants[index], newPropsObj);
            })
        }else if(this.$parent.$options.name=='SubPage'){
            participants = this.globalData.init.participants.sort((a,b)=> (a.teamName > b.teamName ? 1 : -1))
            participants.map( (participant, index) => {
                    const newPropsObj = {
                        'place':index + 1
                    };
                    return Object.assign(participants[index], newPropsObj);
                })
        }
        return participants
    }
  }
}
</script>

<style scoped>
.participants{
    margin-top: 3rem;
}
.col-lg-6{
    display: inline-flex;
}
.team{
    display: flex;
    margin-bottom: 2rem;
}
.left{
    width: 100px;
    height: 100px;
    background: linear-gradient(45deg, #313d6b, #ff6104);
    color: #FFFFFF;
    font-family: 'Proxima Nova Bold', sans-serif;
    font-size: 36px;
    text-align: center;
    padding: 1.5rem;
    line-height: 50px;
    margin-right: 2rem;
}
.right{
    padding:20px 0 0 25px;
}
.team-name{
    font-family: 'Proxima Nova Bold', sans-serif;
    font-size:30px;
    line-height:30px;
    padding:0 0 3px 0;}
.university{
    font-size:16px;
    line-height:16px;
    height:36px;
    font-family: 'Proxima Nova Regular Italic', sans-serif;
}
@media (max-width: 768px) {
    .participants{
        background:none;
        background-color:#fff;
        float:none;
        margin:0 0 30px 0;
    }
    .team-name{padding:0 0 10px 0;}
}

@media screen and (max-width: 576px){
    .no-university.right{
        position: relative;
    }
    .no-university .team-name{
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        width: max-content;
    }
    .right{
        padding: 0;
        margin-left: -1rem;
    }
    .left{
        max-width: 60px;
        min-width: 60px;
        height: 60px;
        line-height: 38px;
        font-size: 28px;
        padding: 0.5rem;
    }
}
@media (max-width:320px) {
    .team-name{font-size: 22px;line-height: 22px;}
 }
</style>