<template>

</template>

<script>
export default {
  name: 'Results',
  props:['globalData','pageId'],
  data(){
    return{
      results : this.globalData.init.results
    }
  }
}
</script>
<style scoped>
.container{
  max-width: 1400px;
}
</style>
